import type { Conversation, Scalars } from '@aurora/shared-generated/types/graphql-schema-types';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import type { Tenant } from '@aurora/shared-types/tenant';

function getRequiredConversationFields(id: Scalars['ID']['output']): Conversation {
  return {
    id,
    topic: null,
    style: null,
    solved: null,
    latestPost: null,
    lastPostTime: null,
    unreadReplyCount: 0,
    isSubscribed: false,
    isThreadParticipant: false
  };
}

function getDefaultConversation(id: Scalars['ID']['output']): Required<Conversation> {
  return {
    ...getRequiredConversationFields(id),
    __typename: 'Conversation',
    lastPostingActivityTime: null
  };
}

export default function placeholderConversation(
  tenant: Tenant,
  conversation: Partial<Conversation> = {},
  useDefaults = true
): Conversation {
  const id = conversation?.id ?? IdConverter.encodeId(tenant, `MockTopic:${Date.now()}`);
  return {
    id,
    ...(useDefaults ? getDefaultConversation(id) : getRequiredConversationFields(id)),
    ...conversation
  };
}
