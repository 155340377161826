import type { ApolloCache, DataProxy, FetchResult } from '@apollo/client';
import PaginationHelper, {
  PaginationUpdateStrategy
} from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import type {
  MessagePageOrReplyPageAndParams,
  MessageReplyPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import type { CustomRouter } from '@aurora/shared-client/routes/useCustomRouter';
import type {
  Conversation,
  Entity,
  Message,
  MessageConstraints,
  MessageEdge,
  MessageSorts,
  PageInfo,
  ReplyMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ImageAssociationType,
  SortDirection,
  VideoAssociationType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { Tenant } from '@aurora/shared-types/tenant';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import acceptedSolutionFragment from '../../../components/messages/AcceptedSolutionButton/AcceptedSolutionButton.fragment.graphql';
import messageRepliesFragment from '../../../components/messages/MessageReplies.fragment.graphql';
import messageRepliesQuery from '../../../components/messages/MessageReplies.query.graphql';
import type { MessageTypes } from '../../../components/messages/MessageView/types';
import messageViewsQuery from '../../../components/messages/MessageViews.query.graphql';
import { getCachedEntityViewVariables } from '../../../components/useEntityViewQuery';
import { ApolloQueryCacheKey, ItemType, MessageViewVariant } from '../../../types/enums';
import type {
  AcceptedSolutionButtonFragment,
  CreateBlogArticleCommentMutation,
  CreateBlogArticleMutation,
  CreateForumReplyMutation,
  CreateForumTopicMutation,
  CreateIdeaCommentMutation,
  CreateIdeaContentMutation,
  CreateOccasionReplyMutation,
  CreateTkbArticleCommentMutation,
  CreateTkbArticleMutation,
  MessageBasicFieldsFragment,
  MessageBoardFragment,
  MessageConversationFragment,
  MessageLinkFragment,
  MessageRepliesCountFragment,
  MessageRepliesFragment,
  MessageRepliesQueryVariables,
  MessageReplyButtonFragment,
  MessageViewsQuery,
  MessageViewsQueryVariables,
  UpdateBlogArticleCommentMutation,
  UpdateBlogArticleMutation,
  UpdateForumReplyMutation,
  UpdateForumTopicMutation,
  UpdateIdeaCommentMutation,
  UpdateIdeaMutation,
  UpdateTkbArticleCommentMutation,
  UpdateTkbArticleMutation
} from '../../../types/graphql-types';
import ConversationStyleBehaviorHelper from '../../boards/ConversationStyleBehaviorHelper';
import placeholderAcceptedSolutionMessage from '../../placeholders/placeholderAcceptedSolutionMessage';
import placeholderConversation from '../../placeholders/placeholderConversation';
import placeholderMessage from '../../placeholders/placeholderMessage';
import placeholderMessageConnection from '../../placeholders/placeholderMessageConnection';
import placeholderMessageEdge from '../../placeholders/placeholderMessageEdge';
import placeholderPageInfo from '../../placeholders/placeholderPageInfo';
import type { EndUserRouter } from '@aurora/shared-client/routes/useEndUserRoutes';

function updateCache<T>(
  tenant: Tenant,
  idToUpdateReplyCount: string,
  cache: ApolloCache<T>,
  fragment,
  fragmentName,
  cachedVariables,
  createMessage?,
  parentMessage?,
  updateStrategy?
) {
  const variables: MessageRepliesQueryVariables = {
    ...cachedVariables,
    id: idToUpdateReplyCount
  };

  // Read the data from our cache for this query.
  const cachedFragment = cache.readFragment<MessageRepliesFragment>({
    id: idToUpdateReplyCount,
    fragment,
    fragmentName,
    variables
  });

  if (!cachedFragment) {
    return;
  }

  const { replies } = cachedFragment;
  const edges = replies?.edges ?? [];
  const pageInfo = (replies?.pageInfo ?? placeholderPageInfo) as PageInfo;

  const newEdge: MessageEdge = {
    node: placeholderMessage(
      tenant,
      {
        ...(createMessage as Message),
        board: parentMessage.board,
        conversation: parentMessage.conversation,
        repliesCount: 0,
        replies: placeholderMessageConnection({ edges: [] })
      },
      true
    ),
    cursor: null
  };

  const data = {
    ...cachedFragment,
    replies: {
      edges:
        createMessage && parentMessage && newEdge
          ? PaginationHelper.mergeItems(updateStrategy, edges, [newEdge])
          : edges,
      pageInfo,
      __typename: 'MessageConnection'
    }
  };

  cache.writeFragment({
    id: idToUpdateReplyCount,
    fragment: messageRepliesFragment,
    fragmentName,
    data,
    variables
  });
  return data;
}

/**
 * * Build sorts for solutions list. This method lives here to avoid a circular dependency.
 */
function buildSolutionsListSorts(): MessageSorts {
  return {
    postTime: {
      direction: SortDirection.Asc
    }
  };
}

/**
 * Build constraints for solutions list. This method lives here to avoid a circular dependency.
 * @param message - the message
 */
function buildSolutionsListConstraints(message: Entity): MessageConstraints {
  return {
    topicId: {
      eq: message.id
    },
    solution: {
      eq: true
    }
  };
}

function getAddReplyMessageFromResult(
  fetchResult: FetchResult<
    | CreateForumTopicMutation
    | CreateForumReplyMutation
    | CreateBlogArticleMutation
    | CreateBlogArticleCommentMutation
    | CreateTkbArticleMutation
    | CreateTkbArticleCommentMutation
    | CreateIdeaContentMutation
    | CreateIdeaCommentMutation
    | CreateOccasionReplyMutation
  >
) {
  if ((fetchResult?.data as CreateForumTopicMutation).createForumTopic != undefined) {
    return (fetchResult.data as CreateForumTopicMutation).createForumTopic?.result;
  } else if ((fetchResult?.data as CreateBlogArticleMutation).createBlogArticle != undefined) {
    return (fetchResult.data as CreateBlogArticleMutation).createBlogArticle?.result;
  } else if ((fetchResult?.data as CreateTkbArticleMutation).createTkbArticle != undefined) {
    return (fetchResult.data as CreateTkbArticleMutation).createTkbArticle?.result;
  } else if ((fetchResult?.data as CreateForumReplyMutation).createForumReply != undefined) {
    return (fetchResult.data as CreateForumReplyMutation).createForumReply?.result;
  } else if (
    (fetchResult?.data as CreateBlogArticleCommentMutation).createBlogArticleComment != undefined
  ) {
    return (fetchResult.data as CreateBlogArticleCommentMutation).createBlogArticleComment?.result;
  } else if (
    (fetchResult?.data as CreateTkbArticleCommentMutation).createTkbArticleComment != undefined
  ) {
    return (fetchResult.data as CreateTkbArticleCommentMutation).createTkbArticleComment?.result;
  } else if ((fetchResult?.data as CreateIdeaContentMutation).createIdea != undefined) {
    return (fetchResult.data as CreateIdeaContentMutation).createIdea?.result;
  } else if ((fetchResult?.data as CreateIdeaCommentMutation).createIdeaComment != undefined) {
    return (fetchResult.data as CreateIdeaCommentMutation).createIdeaComment?.result;
  } else if ((fetchResult?.data as CreateOccasionReplyMutation).createOccasionReply != undefined) {
    return (fetchResult.data as CreateOccasionReplyMutation).createOccasionReply?.result;
  }
}

/**
 * Adds a new message to the Apollo Client Cache, which will trigger all observable queries to update.
 *
 * @param tenant the current tenant
 * @param parentMessage the parent message to add the reply to
 * @param cache the ApolloClient cache
 * @param fetchResult the `FetchResult` from the create message mutation that contains the new reply
 * @param updateStrategy the update strategy; controls how the new message is added to the existing list
 */
function addReplyToMessageInCache<T>(
  tenant: Tenant,
  parentMessage: MessageBasicFieldsFragment & MessageConversationFragment & MessageBoardFragment,
  cache: ApolloCache<T>,
  fetchResult: FetchResult<
    | CreateForumTopicMutation
    | CreateForumReplyMutation
    | CreateBlogArticleMutation
    | CreateBlogArticleCommentMutation
    | CreateTkbArticleMutation
    | CreateTkbArticleCommentMutation
    | CreateIdeaContentMutation
    | CreateIdeaCommentMutation
    | CreateOccasionReplyMutation
  >,
  updateStrategy = PaginationUpdateStrategy.APPEND
): void {
  const createMessage = getAddReplyMessageFromResult(fetchResult);
  if (parentMessage && createMessage?.depth === 1) {
    const id = cache.identify(parentMessage);
    const { id: parentId, depth, revisionNum } = parentMessage;
    const isTopic = depth === 0;
    const cacheKey =
      (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
      `:${parentId}:${revisionNum}`;
    const fragment = messageRepliesFragment;
    const fragmentName = 'MessageReplies';
    const cachedVariables = getCachedEntityViewVariables<
      ItemType.MESSAGE,
      MessageRepliesQueryVariables
    >(
      cache,
      ItemType.MESSAGE,
      { type: MessageViewVariant.STANDARD },
      messageRepliesQuery,
      cacheKey
    );

    updateCache(
      tenant,
      id,
      cache,
      fragment,
      fragmentName,
      cachedVariables,
      createMessage,
      parentMessage,
      updateStrategy
    );
  }
}

function getUpdateReplyMessageFromResult(
  fetchResult: FetchResult<
    | UpdateForumTopicMutation
    | UpdateForumReplyMutation
    | UpdateBlogArticleMutation
    | UpdateBlogArticleCommentMutation
    | UpdateTkbArticleMutation
    | UpdateTkbArticleCommentMutation
    | UpdateIdeaMutation
    | UpdateIdeaCommentMutation
  >
) {
  if ((fetchResult?.data as UpdateForumReplyMutation).updateForumReply != undefined) {
    return (fetchResult.data as UpdateForumReplyMutation).updateForumReply?.result;
  } else if (
    (fetchResult?.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment != undefined
  ) {
    return (fetchResult.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment?.result;
  } else if (
    (fetchResult?.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment != undefined
  ) {
    return (fetchResult.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment?.result;
  } else if ((fetchResult?.data as UpdateForumReplyMutation).updateForumReply != undefined) {
    return (fetchResult?.data as UpdateForumReplyMutation).updateForumReply?.result;
  } else if (
    (fetchResult?.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment != undefined
  ) {
    return (fetchResult?.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment?.result;
  } else if (
    (fetchResult?.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment != undefined
  ) {
    return (fetchResult?.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment?.result;
  }
}

/**
 * Update a new message to the Apollo Client Cache, which will trigger all observable queries to update.
 *
 * @param tenant the current tenant
 * @param message the message which is edited
 * @param cache the ApolloClient cache
 * @param fetchResult the `FetchResult` from the update message mutation that contains the edited message
 */
function updateReplyToMessageInCache<T>(
  tenant: Tenant,
  message: Message,
  cache: ApolloCache<T>,
  fetchResult: FetchResult<
    | UpdateForumTopicMutation
    | UpdateForumReplyMutation
    | UpdateBlogArticleMutation
    | UpdateBlogArticleCommentMutation
    | UpdateTkbArticleMutation
    | UpdateTkbArticleCommentMutation
  >
): void {
  const updateMessage = getUpdateReplyMessageFromResult(fetchResult);
  const { parent } = message as ReplyMessage;
  const id = cache.identify(parent);
  const { id: parentId, revisionNum } = parent as Message;
  const { depth } = updateMessage;
  const isTopic = depth === 0;
  const cacheKey =
    (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${parentId}:${revisionNum}`;
  const fragment = messageRepliesFragment;
  const fragmentName = 'MessageReplies';
  const cachedVariables = getCachedEntityViewVariables<
    ItemType.MESSAGE,
    MessageRepliesQueryVariables
  >(cache, ItemType.MESSAGE, { type: MessageViewVariant.STANDARD }, messageRepliesQuery, cacheKey);
  const variables: MessageRepliesQueryVariables = {
    ...cachedVariables,
    id
  };

  // Read the data from our cache for this query.
  const cachedFragment = cache.readFragment<MessageRepliesFragment>({
    id,
    fragment,
    fragmentName,
    variables
  });

  const { replies } = cachedFragment;
  const edges = replies?.edges ?? [];
  const pageInfo = (replies?.pageInfo ?? placeholderPageInfo) as PageInfo;

  let newEdge: MessageEdge;
  if (message && updateMessage) {
    newEdge = {
      node: placeholderMessage(
        tenant,
        {
          ...(updateMessage as Message),
          board: message.board,
          conversation: message.conversation
        },
        true
      ),
      cursor: null
    };
  }
  const data = {
    ...cachedFragment,
    replies: {
      edges: PaginationHelper.mergeItems(PaginationUpdateStrategy.REPLACE, edges, [newEdge]),
      pageInfo,
      __typename: 'MessageConnection'
    }
  };

  cache.writeFragment({
    id,
    fragment: messageRepliesFragment,
    fragmentName,
    data,
    variables
  });
}

/**
 * Update the solutions list cache after a message solution state change.
 *
 * @param tenant The current tenant
 * @param cache The apollo cache
 * @param message The updated message
 * @param isSolution Whether the message is a solution (after the change)
 */
function updateSolutionsListCache(
  tenant: Tenant,
  cache: ApolloCache<{}>,
  message: Entity & MessageConversationFragment & MessageBoardFragment,
  isSolution: boolean
): MessageViewsQuery {
  const cachedVariables = getCachedEntityViewVariables<
    ItemType.MESSAGE,
    MessageRepliesQueryVariables
  >(
    cache,
    ItemType.MESSAGE,
    { type: MessageViewVariant.STANDARD },
    messageViewsQuery,
    'MessageSolutions',
    true
  );

  const constraints: MessageConstraints = buildSolutionsListConstraints(message.conversation.topic);

  const sorts: MessageSorts = buildSolutionsListSorts();

  const variables: MessageViewsQueryVariables = {
    ...cachedVariables,
    constraints,
    sorts
  };

  const cacheResult = cache.readQuery<MessageViewsQuery, MessageViewsQueryVariables>({
    query: messageViewsQuery,
    variables
  });

  if (!cacheResult) {
    return;
  }

  const { messages } = cacheResult;
  let { edges: updatedEdges } = messages;
  if (isSolution) {
    updatedEdges = [
      ...messages.edges,
      placeholderMessageEdge(tenant, {
        node: placeholderAcceptedSolutionMessage(tenant, {
          ...(message as Partial<Message>),
          solution: true
        })
      })
    ];
  } else {
    updatedEdges = messages.edges.filter(edge => edge?.node?.id !== message?.id);
  }

  const data: MessageViewsQuery = {
    messages: placeholderMessageConnection({
      ...messages,
      edges: updatedEdges as MessageEdge[],
      pageInfo: placeholderPageInfo(messages?.pageInfo)
    })
  };

  cache.writeQuery<MessageViewsQuery, MessageViewsQueryVariables>({
    query: messageViewsQuery,
    variables,
    data
  });

  return data;
}

/**
 * Update the message cache after a message solution state change.
 *
 * @param tenant The tenant
 * @param cache The apollo cache
 * @param message The updated message
 * @param isSolution Whether the message is a solution (after the change)
 */
function updateSolutionMessageCache(
  tenant: Tenant,
  cache: ApolloCache<{}>,
  message: Entity,
  isSolution
): void {
  const cacheId = cache.identify(message);
  const fragmentOptions: DataProxy.Fragment<{}, Entity> = {
    id: cacheId,
    fragment: acceptedSolutionFragment,
    fragmentName: 'AcceptedSolutionButton',
    variables: getCachedEntityViewVariables<ItemType.MESSAGE, MessageRepliesQueryVariables>(
      cache,
      ItemType.MESSAGE,
      { type: MessageViewVariant.STANDARD },
      messageRepliesQuery,
      cacheId
    )
  };

  const messageFragment = cache.readFragment<AcceptedSolutionButtonFragment, {}>(fragmentOptions);

  const updatedMessageFragment: AcceptedSolutionButtonFragment = {
    ...messageFragment,
    solution: isSolution,
    conversation: placeholderConversation(tenant, {
      ...(messageFragment.conversation as Partial<Conversation>),
      solved: isSolution
    })
  };

  cache.writeFragment<AcceptedSolutionButtonFragment>({
    ...fragmentOptions,
    data: updatedMessageFragment
  });
}

/**
 * Removes a reply from a parent message in the Apollo cache.
 *
 * @param tenant The current tenant.
 * @param cache The apollo cache.
 * @param parentMessage The parent message of the reply.
 * @param messageToRemove The reply that should be removed from the parent message's replies.
 */
function removeCachedReplyFromMessage<T>(
  tenant: Tenant,
  cache: ApolloCache<T>,
  parentMessage: MessageBasicFieldsFragment & MessageRepliesCountFragment & MessageBoardFragment,
  messageToRemove: MessageBasicFieldsFragment &
    MessageRepliesCountFragment &
    MessageBoardFragment &
    MessageConversationFragment
): void {
  const id = cache.identify(parentMessage);
  const { id: parentId, revisionNum: parentRevision } = parentMessage;
  const isParentTopic = messageToRemove.depth - 1 === 0;
  const cacheKey =
    (isParentTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${parentId}:${parentRevision}`;
  const fragment = messageRepliesFragment;
  const fragmentName = 'MessageReplies';
  const variables = getCachedEntityViewVariables<ItemType.MESSAGE, MessageRepliesQueryVariables>(
    cache,
    ItemType.MESSAGE,
    { type: MessageViewVariant.STANDARD },
    messageRepliesQuery,
    cacheKey
  );
  const cachedParentMessage = cache.readFragment<MessageRepliesFragment>({
    id,
    fragment,
    fragmentName,
    variables
  });

  if (!cachedParentMessage) {
    return;
  }

  const originalEdges = cachedParentMessage?.replies?.edges ?? [];
  const { repliesCount } = parentMessage;
  const updatedEdges = originalEdges.filter(edge => edge?.node?.id !== messageToRemove?.id);

  const data = {
    ...cachedParentMessage,
    repliesCount: repliesCount - 1,
    replies: placeholderMessageConnection({
      edges: updatedEdges as MessageEdge[],
      pageInfo: placeholderPageInfo(cachedParentMessage?.replies?.pageInfo)
    })
  };

  cache.writeFragment<MessageRepliesFragment>({
    id,
    fragment,
    fragmentName,
    data,
    variables
  });

  updateSolutionsListCache(tenant, cache, messageToRemove, false);
  updateSolutionMessageCache(tenant, cache, messageToRemove, false);
}

/**
 * Get the route and params for the supplied message.
 *
 * @param message the message.
 */
function getMessageRouteAndParams(
  message: MessageLinkFragment | MessageReplyButtonFragment
): MessagePageOrReplyPageAndParams {
  const {
    uid,
    board,
    depth,
    conversation: { topic }
  } = message;

  const { messagePage, messageReplyPage } = ConversationStyleBehaviorHelper.getInstance(board);
  const messageSubject = UrlHelper.determineSlugForMessagePath(message);

  if (depth === 0) {
    return {
      route: messagePage,
      params: {
        boardId: message.board.displayId,
        messageSubject,
        messageId: uid.toString()
      }
    };
  } else {
    return {
      route: messageReplyPage,
      params: {
        boardId: message.board.displayId,
        messageSubject,
        messageId: topic.uid.toString(),
        replyId: uid.toString()
      }
    };
  }
}

/**
 * Clears a message kudos list query cache after a kudo is added or removed
 *
 * @param cache The apollo cache
 * @param messageType the message type
 * @param messageId id to identify the cached query that needs update
 */
function clearMessageKudosCache(
  cache: ApolloCache<{}>,
  messageType: MessageTypes,
  messageId: string
): void {
  cache.modify({
    id: `${messageType}:${messageId}`,
    fields: {
      kudos: (existing, { DELETE }) => {
        return DELETE;
      }
    }
  });
}
function getMessageReplyFullyQualifiedUrl(
  message: MessageReplyButtonFragment,
  router: CustomRouter<EndUserPages, EndUserQueryParams>,
  tenant: Tenant
): string {
  const { route, params } = getMessageRouteAndParams(message) as MessageReplyPagesAndParams;
  const relativeUrlForRoute = router.getRelativeUrlForRoute<MessageReplyPagesAndParams>(
    route,
    params
  );
  return UrlHelper.getFullyQualifiedUrlForPath(tenant, relativeUrlForRoute);
}

/**
 * Checks whether video is present in message teaser.
 */
function isTeaserVideoPresent(message: Message): Boolean {
  return (
    message?.videos?.edges?.some(
      video => video?.node?.videoAssociationType === VideoAssociationType.InlineTeaser
    ) ?? false
  );
}

/**
 * Checks whether image is present in message teaser.
 */
function isTeaserImagePresent(message: Message): Boolean {
  return (
    message?.images?.edges?.some(
      image =>
        image?.node?.associationType === ImageAssociationType.Teaser ||
        image?.node?.associationType === ImageAssociationType.Cover
    ) ?? false
  );
}

/**
 * Returns the trimmed value and handles nbsp characters.
 * mainly to remove the 'nbsp;' from the processing text i.e. 'Processing Video... \n &nbsp; '
 */
function removeExtraSpaces(content: string): string {
  return content.replaceAll(/&nbsp;|\u00A0/g, '');
}

/**
 * Function to determine if the View All Messages page should be indexable or not
 */
function isPageIndexable(router: EndUserRouter): boolean {
  const queryParams = Object.keys(router.getQueryParams());
  return (
    queryParams.length === 0 ||
    (queryParams.length === 1 && queryParams.includes(EndUserQueryParams.AFTER))
  );
}

/**
 *
 * @param contextMessage The message the principal user is viewing, or null if no message is being viewed
 * @param router Current app router
 * @param getCaseSensitivePath Function to identify lowercase node id based on seo setting
 *
 * @returns Route and params for redirect on root message pages
 */

export {
  buildSolutionsListSorts,
  buildSolutionsListConstraints,
  addReplyToMessageInCache,
  updateSolutionsListCache,
  updateSolutionMessageCache,
  removeCachedReplyFromMessage,
  getMessageRouteAndParams,
  updateReplyToMessageInCache,
  clearMessageKudosCache,
  getMessageReplyFullyQualifiedUrl,
  isTeaserImagePresent,
  isTeaserVideoPresent,
  removeExtraSpaces,
  isPageIndexable
};
