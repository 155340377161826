import type { MessageEdge } from '@aurora/shared-generated/types/graphql-schema-types';
import type { Tenant } from '@aurora/shared-types/tenant';
import placeholderMessage from './placeholderMessage';

export function getDefaultMessageEdge(tenant: Tenant): Required<MessageEdge> {
  return {
    __typename: 'MessageEdge',
    cursor: null,
    node: placeholderMessage(tenant)
  };
}

export default function placeholderMessageEdge(
  tenant: Tenant,
  messageEdge: Partial<MessageEdge> = {},
  useDefaults = true
): MessageEdge {
  return {
    ...(useDefaults ? getDefaultMessageEdge(tenant) : { node: placeholderMessage(tenant) }),
    ...messageEdge
  };
}
